import { combineReducers } from 'redux';

import userReducer from './user';
import detailsReducer from './details';
import inventoryReducer from './inventory';
import calendarReducer from './calendar';
import bookingsReducer from './bookings';
import bookingReducer from './booking';
import searchReducer from './search';

export default combineReducers({
  user: userReducer,
  details: detailsReducer,
  inventory: inventoryReducer,
  calendar: calendarReducer,
  bookings: bookingsReducer,
  search: searchReducer,
  booking: bookingReducer,
});
