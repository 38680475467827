import {
  REQUEST,
  SUCCESS,
  FAILURE,
  USER_LOGIN,
  USER_LOGOUT,
  USER_HOTEL,
  USER_RESTORE,
} from '@/redux/store/action-types';


const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  selectedHotel: JSON.parse(localStorage.getItem('selectedHotel')) || null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case USER_LOGIN[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case USER_LOGIN[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case USER_LOGIN[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };

    case USER_LOGOUT:
      return {
        ...state,
        ...action.payload,
      };
    case USER_LOGOUT[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case USER_LOGOUT[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case USER_LOGOUT[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };

    case USER_RESTORE[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case USER_RESTORE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case USER_RESTORE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };

    case USER_HOTEL[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case USER_HOTEL[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case USER_HOTEL[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
