import {
  REQUEST,
  SUCCESS,
  FAILURE,
  USER_LOGIN,
  USER_LOGOUT,
  USER_HOTEL,
  USER_RESTORE,
} from '@/redux/store/action-types';
import axios from 'axios';
import action from '../action';


export const loginUser = (email, pass) => async (dispatch) => {
  dispatch(action(USER_LOGIN[REQUEST]));
  try {
    // TODO env props
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/signin`, { email, password: pass });
    if (res.data.token) {
      const user = {
        ...res.data.user,
        hotels: res.data.hotels,
      };

      localStorage.setItem('token', res.data.token);
      localStorage.setItem('user', JSON.stringify(user));
      axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;

      let selectedHotel = { products: [] };
      if (res.data.hotels.length > 0) {
        const hotel = res.data.hotels[0];
        const hotelProducts = await axios.get(`${process.env.REACT_APP_API_URL}/user/hotel-products/${hotel.id}`);
        selectedHotel = {
          ...hotel,
          products: hotelProducts.data.products,
        };

        localStorage.setItem('selectedHotel', JSON.stringify(selectedHotel));
      }

      dispatch(action(USER_LOGIN[SUCCESS], {
        user,
        selectedHotel,
      }));

      return true;
    }
    dispatch(action(USER_LOGIN[FAILURE], { error: 'No such user' }));
  } catch (error) {
    dispatch(action(USER_LOGIN[FAILURE], { error }));
  }
  return false;
};

export const restoreUser = email => async (dispatch) => {
  dispatch(action(USER_RESTORE[REQUEST]));
  try {
    const res = await axios.post(`${process.env.REACT_APP_WEB_API}/api/v1/users/reset_password`, { email });
    if (res.data) {
      dispatch(action(USER_RESTORE[SUCCESS]));
      return true;
    }
    dispatch(action(USER_RESTORE[FAILURE], { error: 'No such user' }));
  } catch (error) {
    dispatch(action(USER_RESTORE[FAILURE], { error }));
  }
  return false;
};


export const logoutUser = () => async (dispatch) => {
  dispatch(action(USER_LOGOUT[REQUEST]));
  try {
    localStorage.clear();
    axios.defaults.headers.common.Authorization = '';

    dispatch(action(USER_LOGOUT[SUCCESS], {
      user: null,
    }));
  } catch (error) {
    dispatch(action(USER_LOGOUT[FAILURE], { error }));
  }
};


export const changeHotel = hotel => async (dispatch) => {
  dispatch(action(USER_HOTEL[REQUEST]));
  try {
    const hotelProducts = await axios.get(`${process.env.REACT_APP_API_URL}/user/hotel-products/${hotel.id}`);
    const selectedHotel = {
      ...hotel,
      products: hotelProducts.data.products,
    };
    localStorage.setItem('selectedHotel', JSON.stringify(selectedHotel));

    dispatch(action(USER_HOTEL[SUCCESS], {
      selectedHotel,
    }));
  } catch (error) {
    dispatch(action(USER_HOTEL[FAILURE], { error }));
  }
};
