/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  // Hotel
  fetchHotel,
  saveHotel,
  deleteHotelPolicy,
  deleteHotel,
  changeHotel,
  getHotels,
  // Market
  saveHotelMarket,
  deleteHotelMarket,
  // Hotel Amenitie
  fetchHotelAmenitie,
  saveHotelAmenitie,
  deleteAmenitie,
  deleteHotelAmenitie,
  // Image
  getHotelImage,
  deleteImage,
  hardDeleteImage,
} from '@/redux/actions';

import { Button, Modal } from 'antd';
import './hotel-page.css';
import AddEditHotelForm from '../AddHotelPage/AddEditHotelForm';

const { confirm } = Modal;

class EditHotelPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      hotelPolicies: this.props.hotelPolicies,
      hotelAmenities: this.props.hotelAmenities,
      amenities: this.props.amenities,
      changedHotel: this.props.hotel,
      selectedMarket: this.props.selectedMarket,
    };
  }

  async componentDidMount() {
    await this.fetchHotel(this.props.selectedHotel.id);
    await this.fetchHotelAmenities(this.props.selectedHotel.id);
    await this.fetchHotelImage(this.props.selectedHotel.id);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedHotel.id !== this.props.selectedHotel.id) {
      await this.fetchHotel(this.props.selectedHotel.id);
      await this.fetchHotelAmenities(this.props.selectedHotel.id);
      await this.fetchHotelImage(this.props.selectedHotel.id);
    }
  }

  fetchHotel = async (hotelId) => {
    await this.props.actions.fetchHotel(hotelId);
    this.setState({
      changedHotel: this.props.hotel,
      selectedMarket: this.props.selectedMarket,
      IsPublished: this.props.hotel.IsPublished,
      IsComingSoon: this.props.hotel.IsComingSoon,
    });
  }

  fetchHotelAmenities = async (hotelId) => {
    await this.props.actions.fetchHotelAmenitie(hotelId);
    this.setState({
      hotelAmenities: this.props.hotelAmenities,
    });
  }

  fetchHotelImage = async (hotelId) => {
    await this.props.actions.getHotelImage(hotelId);
  }

  saveHotel = async (changedHotel) => {
    await this.props.actions.saveHotel(changedHotel);
    return changedHotel.HotelId;
  }

  processHotelAmenitie = async (hotelAmenitie) => {
    await this.props.actions.saveHotelAmenitie(hotelAmenitie);
    return hotelAmenitie.Id;
  }

  // deleteHotel = async () => {
  //   this.state.hotelPolicies.forEach((item) => {
  //     this.props.actions.deleteHotelPolicy(item);
  //   });

  //   this.state.amenities.forEach((item) => {
  //     this.props.actions.deleteAmenitie(item);
  //   });

  //   this.props.uploadedFiles.forEach((item) => {
  //     this.props.actions.deleteImage(item.Id);
  //     this.props.actions.hardDeleteImage(item.Id);
  //   });

  //   await this.props.actions.deleteHotelAmenitie(this.state.hotelAmenities);
  //   await this.props.actions.deleteHotelMarket(this.state.selectedMarket);
  //   await this.props.actions.deleteHotel(this.state.changedHotel);

  //   const selectedHotel = this.props.user.hotels[0];
  //   await this.props.actions.changeHotel(selectedHotel);

  //   await this.props.actions.getHotels();

  //   await this.props.history.push('/calendar');
  // }

  showDeleteConfirm = (thisInstance) => {
    confirm({
      title: 'Are you sure?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        thisInstance.deactivateHotel();
      },
      onCancel() {},
    });
  }

  deactivateHotel = async () => {
    this.state.amenities.forEach((item) => {
      const amenitie = { ...item, IsActive: false };
      this.props.actions.changeActiveAmenitie(amenitie);
    });

    const hotelAmenities = { ...this.state.hotelAmenities, IsActive: false };
    await this.props.actions.saveHotelAmenitie(hotelAmenities);

    const changedHotel = { ...this.state.changedHotel, IsDelete: true };
    await this.props.actions.saveHotel(changedHotel);

    const selectedHotel = this.props.user.hotels[0];
    await this.props.actions.changeHotel(selectedHotel);

    await this.props.actions.getHotels();

    await this.props.history.push('/calendar');
  }

  render() {
    return (
      <div className="wrap">
        <div className="content">
          <div className="flex-container page">
            <AddEditHotelForm
              saveHotel={this.saveHotel}
              processHotelMarket={this.props.actions.saveHotelMarket}
              processHotelAmenitie={this.processHotelAmenitie}
              {...this.props}
            />
            {this.props.user.isSuperAdmin && (
              <div className="flex-container discount-code" style={{ paddingTop: '60px', width: 830 }}>
                {/* <div className="flex-element">
                  <Button type="primary" style={{ marginTop: '15px' }} onClick={() => this.deleteHotel()}>Delete</Button>
                </div> */}
                <div className="flex-element">
                  <Button
                    type="primary"
                    style={{ backgroundColor: 'red', marginTop: '15px' }}
                    onClick={async () => { await this.showDeleteConfirm(this); }}
                  >
                    Deactivate
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  selectedHotel: state.user.selectedHotel,
  hotel: state.user.hotel,
  policies: state.policy.policies,
  selectedPolicy: state.policy.selectedPolicy,
  hotelPolicies: state.policy.hotelPolicies,
  hotelAmenities: state.amenities.hotelAmenities,
  amenities: state.amenities.amenities,
  selectedMarket: state.user.selectedMarket,
  uploadedFiles: state.image.images,
}
);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    // Hotel
    fetchHotel,
    saveHotel,
    deleteHotelPolicy,
    deleteHotel,
    changeHotel,
    getHotels,
    // Market
    saveHotelMarket,
    deleteHotelMarket,
    // Hotel Amentie
    fetchHotelAmenitie,
    saveHotelAmenitie,
    deleteAmenitie,
    deleteHotelAmenitie,
    // Image
    getHotelImage,
    deleteImage,
    hardDeleteImage,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditHotelPage);
