/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchSearch,
  redeemSearchBooking,
  downloadSearchExcel,
} from '@/redux/actions';
import moment from 'moment';
import {
  Table,
  Button,
  Input,
  DatePicker,
  Icon,
  Modal,
  Descriptions,
} from 'antd';
import debounce from 'lodash.debounce';
import './search-page.css';

const { RangePicker } = DatePicker;

class SearchPage extends React.Component {
  state = {
    modalVisible: false,
    content: '',
    sortField: 'CheckinDate',
    sortOrder: 'ascend',
  };

 columns = [
   {
     title: 'ID',
     dataIndex: 'BookingId',
     key: 'BookingId',
     sorter: true,
     width: 20,
     render: (val, record) => <Link to={`/booking/${val}`}>{`${val}`}</Link>,
   },
   {
     title: 'Code',
     dataIndex: 'BookingCode',
     key: 'BookingCode',
     className: 'column-word-wrap',
     /* onCell: () => ({
       style: {
         whiteSpace: 'nowrap',
         maxWidth: 80,
       },
     }),
     render: text => (
       <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
         {text}
       </div>
     ), */
   },
   {
     title: 'Name',
     dataIndex: 'FirstName',
     key: 'FirstName',
     render: (val, record) => (
       <React.Fragment>
         {record.FirstName}
         {' '}
         {record.LastName}
         <br />
         {record.EmailAddress}
       </React.Fragment>

     ),
     sorter: true,
   },
   {
     title: 'Plan',
     dataIndex: 'SubscriptionName',
     key: 'SubscriptionName',
     render: (val, record) => (
       <span>
         {record.IsActiveSubscription ? 'Gold Pass' : 'Regular Pass'}
       </span>
     ),
   },
   {
     title: 'Hotel',
     dataIndex: 'HotelName',
     key: 'HotelName',
     /* onCell: () => ({
       style: {
         whiteSpace: 'nowrap',
         maxWidth: 80,
       },
     }),
     render: text => (
       <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
         {text}
       </div>
     ), */
   },
   {
     title: 'Product',
     dataIndex: 'ProductName',
     key: 'ProductName',
     render: (val, record) => (
       <span>
         {record.IsActiveSubscription ? 'Gold Day Pass' : record.ProductName}
       </span>
     ),
     sorter: true,
   },
   /* {
     title: 'Tickets',
     dataIndex: 'Quantity',
     key: 'Quantity',
   }, */
   {
     title: 'Total',
     dataIndex: 'TotalPrice',
     key: 'TotalPrice',
     render: (val, record) => (
       `${record.Quantity} x $${val}`
     ),
   },
   {
     title: 'Purchase',
     dataIndex: 'BookedDate',
     key: 'BookedDate',
     sorter: true,
     render: date => (
       <span>
         {moment.utc(date).format('MM/DD/YYYY')}
       </span>
     ),
   },
   {
     title: 'Check-In',
     dataIndex: 'CheckinDate',
     key: 'CheckinDate',
     defaultSortOrder: 'descend',
     sorter: true,
     render: date => (
       <span>
         {moment.utc(date).format('MM/DD/YYYY')}
       </span>
     ),
   },
   {
     title: 'Status',
     dataIndex: 'PassStatus',
     key: 'PassStatus',
     sorter: true,
     render: (status) => {
       const statuses = [
         'Active',
         'Expired',
         'Redeemed',
         'Refunded',
         'Capture',
       ];

       return (
         <span>
           {statuses[status]}
         </span>
       );
     },
   },
   {
     title: 'Action',
     key: 'action',
     width: 10,
     render: (val, record) => {
       if (record.PassStatus !== 2) {
         return (
           <Button type="primary" size="small" onClick={() => this.handleBookingRedeem(record)}>Redeem</Button>

         );
       }
       return (
         <Button type="primary" disabled>Redeem</Button>
       );
     },
   },
 ];

  state = {
    pagination: {},
    searchParam: '',
  };

  debouncedChanges = debounce((newChanges) => {
    this.setState({ searchParam: newChanges }, () => {
      this.fetchBookings();
    });
  }, 300);

  async componentDidMount() {
    this.fetchBookings();
  }

  handleDownload = async () => {
    const hotelId = this.props.user.selectedHotel.id;
    const { searchParam, beginDate, endDate } = this.state;
    await this.props.actions.downloadSearchExcel(hotelId, searchParam, beginDate, endDate);
  };

  handleDatePickerClear = async (dates) => {
    if (dates && dates.length === 0) {
      await this.setState({
        beginDate: null,
        endDate: null,
        page: 1,
      }, async () => this.fetchBookings());
    }
  }

  modalContent = booking => (
    <React.Fragment>
      <Descriptions
        border
        column={{
          xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1,
        }}
      >
        <Descriptions.Item label="Name">{`${booking.FirstName} ${booking.LastName}`}</Descriptions.Item>
        <Descriptions.Item label="Check-In Date">{moment.utc(booking.CheckinDate).format('MM/DD/YYYY')}</Descriptions.Item>
        <Descriptions.Item label="Tickets">{booking.Quantity}</Descriptions.Item>
        <Descriptions.Item label="Each Ticket">{`${booking.MaxGuest} guest(s)`}</Descriptions.Item>
        <Descriptions.Item label="Booking Code">{booking.BookingCode}</Descriptions.Item>
      </Descriptions>
    </React.Fragment>
  )

  handleBookingRedeem = async (booking) => {
    const content = this.modalContent(booking);
    this.setState({ content, modalVisible: true, contentId: booking.BookingId });
  };

  modalOk = async () => {
    const { contentId } = this.state;
    await this.props.actions.redeemSearchBooking(contentId);
    await this.fetchBookings(this.state.page);
    this.setState({ modalVisible: false });
  }

  modalCancel = () => {
    this.setState({ modalVisible: false });
  }

  handleTableChange = async (pagination, filter, sorter) => {
    this.setState({ page: pagination.current, sortField: sorter.field, sortOrder: sorter.order }, () => {
      this.fetchBookings(pagination.current);
    });
  };

  handleDatePickerChange = async (dates) => {
    if (dates.length > 1) {
      await this.setState({
        beginDate: dates[0].format('YYYY-MM-DD'),
        endDate: dates[1].format('YYYY-MM-DD'),
      }, async () => this.fetchBookings(this.state.page));
    }
  }

  handleSearch = async (e) => {
    this.debouncedChanges(e.target.value);
  }

  showTotal = total => `Total ${total} bookings`

  async fetchBookings(pagination = 1) {
    const sortTypes = {
      ascending: 'asc',
      descend: 'desc',
    };
    const {
      searchParam, beginDate, endDate, sortField, sortOrder,
    } = this.state;

    await this.props.actions.fetchSearch(pagination, searchParam, beginDate, endDate, sortField, sortTypes[sortOrder]);
  }

  render() {
    const { bookings, total } = this.props.search;
    const pagination = {
      total,
      pageSize: 10,
      showTotal: this.showTotal,
    };
    const dateFormat = 'MM/DD/YYYY';
    const { content } = this.state;
    return (
      <React.Fragment>
        <Modal
          title="Ticket Validation"
          centered
          visible={this.state.modalVisible}
          onOk={() => this.modalOk()}
          onCancel={() => this.modalCancel()}
          okText="Redeem"
          cancelText="Cancel"
        >
          {content}
        </Modal>
        <div className="search-props">
          <Input
            prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Search guest name, booking code, email"
            allowClear
            onChange={value => this.handleSearch(value)}
          />
          <RangePicker
            defaultValue={[null, null]}
            format={dateFormat}
            onCalendarChange={dates => this.handleDatePickerChange(dates)}
            onChange={this.handleDatePickerClear}
          />
          <Button type="primary" icon="file-excel" onClick={this.handleDownload}>Download Excel</Button>
        </div>
        {console.log(this.props.search.isSearchFetching)}
        <Table
          columns={this.columns}
          rowKey="BookingId"
          dataSource={bookings}
          onChange={this.handleTableChange}
          pagination={pagination}
          size="small"
          className="search-table"
          loading={this.props.search.isSearchFetching}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  search: state.search,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchSearch,
    redeemSearchBooking,
    downloadSearchExcel,
  },
  dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
