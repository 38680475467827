export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const INITIAL = 'INITIAL';

const createTypes = base => [REQUEST, SUCCESS, FAILURE, INITIAL].reduce((acc, type) => {
  acc[type] = `${base}_${type}`;
  return acc;
}, {});

// common
export const USER_LOGIN = createTypes('USER_LOGIN');
export const USER_LOGOUT = createTypes('USER_LOGOUT');
export const USER_RESTORE = createTypes('USER_RESTORE');
export const USER_HOTEL = createTypes('USER_HOTEL');


// details
export const DETAILS_FETCH = createTypes('DETAILS_FETCH');
export const DETAILS_SAVE = createTypes('DETAILS_SAVE');

// inventory
export const INVENTORY_FETCH = createTypes('INVENTORY_FETCH');
export const INVENTORY_SAVE = createTypes('INVENTORY_SAVE');

// calendar
export const CALENDAR_DATE_RANGE = 'CALENDAR_DATE_RANGE';
export const CALENDAR_PRODUCT = createTypes('CALENDAR_PRODUCT');
export const CALENDAR_SET_MONTH = 'CALENDAR_SET_MONTH';
export const CALENDAR_DATE_RANGE_CANCEL = 'CALENDAR_DATE_RANGE_CANCEL';
export const CALENDAR_PRODUCT_SAVE = createTypes('CALENDAR_PRODUCT_SAVE');

// bookings
export const BOOKINGS_FETCH = createTypes('BOOKINGS_FETCH');
export const BOOKINGS_REDEEM = createTypes('BOOKINGS_REDEEM');
export const BOOKINGS_EXCEL = createTypes('BOOKINGS_EXCEL');

// search
export const SEARCH_FETCH = createTypes('SEARCH_FETCH');
export const SEARCH_REDEEM = createTypes('SEARCH_REDEEM');
export const SEARCH_EXCEL = createTypes('SEARCH_EXCEL');

// booking
export const BOOKING_FETCH = createTypes('BOOKING_FETCH');
export const BOOKING_RECEIPT = createTypes('BOOKING_RECEIPT');
export const BOOKING_REFUND = createTypes('BOOKING_REFUND');
export const BOOKING_CHECKIN_DATE = createTypes('BOOKING_CHECKIN_DATE');
