/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Layout,
  Button,
  Form,
  Icon,
  Input,
  Checkbox,
  Typography,
} from 'antd';
import {
  loginUser,
  logoutUser,
} from '@/redux/actions';

import styles from './styles.module.css';

const { Title } = Typography;

const {
  Header, Footer, Sider, Content,
} = Layout;


class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      errorStatus: null,
      error: '',
    };
  }

  componentDidMount() {
    document.title = 'Dayaxe Portal - Login';
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const { username, password } = this.state;

    if (username && password) {
      const result = await this.props.actions.loginUser(username, password);

      if (result) {
        this.props.history.push('/calendar');
      }

      if (!result) {
        this.setState({ errorStatus: 'error', error: 'Email or password is incorrect' });
      }
    }
  }

  render() {
    const { error, errorStatus } = this.state;

    return (
      <Layout className="layout" style={{ height: '100vh' }}>
        <Header>
          <div className="logo" style={{ margin: 0 }}>
            <img src="img/logo.svg" alt="logo" />
          </div>
        </Header>
        <Content style={{ padding: '50px 50px', height: '100%' }}>
          <div className={styles.loginContent}>

            <Form onSubmit={this.handleSubmit} style={{ maxWidth: '300px' }}>
              <div className={styles.loginTitle}>
                <Title level={3}>Login</Title>
              </div>
              <Form.Item
                validateStatus={errorStatus}
              >
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Username"
                  onChange={this.handleChange}
                  name="username"
                />
              </Form.Item>

              <Form.Item
                validateStatus={errorStatus}
                help={error}
              >
                <Input
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password"
                  onChange={this.handleChange}
                  name="password"
                />
              </Form.Item>

              <Form.Item>
                <Checkbox>Remember me</Checkbox>
                <a className="login-form-forgot" style={{ float: 'right ' }} href="/forgot-password">Forgot password</a>
                <Button type="primary" htmlType="submit" style={{ width: '100%' }} className="login-form-button">
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <Button type="link" href="https://dayaxe.zendesk.com/hc/en-us/requests/new">Contact Us</Button>
          <Button type="link" href="https://www.hotels.dayaxe.com/how-it-works">How it works</Button>
          <Button type="link" href="https://www.dayaxe.com/terms">Terms & services</Button>
        </Footer>
      </Layout>

    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    loginUser,
    logoutUser,
  },
  dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
