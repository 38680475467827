import {
  REQUEST,
  SUCCESS,
  FAILURE,
  USER_LOGIN,
  USER_LOGOUT,
  USER_HOTEL,
  USER_RESTORE,
  GET_HOTEL,
  EDIT_HOTEL_FETCH,
  EDIT_HOTEL_SAVE,
  GET_HOTELS,
  HOTEL_MARKETS,
  HOTEL_MARKET_DELETE,
} from '@/redux/store/action-types';


const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  selectedHotel: JSON.parse(localStorage.getItem('selectedHotel')) || null,
  hotel: JSON.parse(localStorage.getItem('hotel')) || null,
  selectedMarket: JSON.parse(localStorage.getItem('selectedMarket')) || null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HOTEL_MARKET_DELETE[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case HOTEL_MARKET_DELETE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case HOTEL_MARKET_DELETE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case HOTEL_MARKETS[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case HOTEL_MARKETS[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        selectedMarket: JSON.parse(localStorage.getItem('selectedMarket')) || null,
      };
    case HOTEL_MARKETS[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case GET_HOTEL[REQUEST]:
      return {
        ...state,
      };
    case GET_HOTEL[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        hotel: JSON.parse(localStorage.getItem('hotel')) || null,
        selectedMarket: JSON.parse(localStorage.getItem('selectedMarket')) || null,
      };
    case GET_HOTEL[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case GET_HOTELS[REQUEST]:
      return {
        ...state,
      };
    case GET_HOTELS[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        user: JSON.parse(localStorage.getItem('user')) || null,
      };
    case GET_HOTELS[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case USER_LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case USER_LOGIN[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case USER_LOGIN[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case USER_LOGIN[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };

    case USER_LOGOUT:
      return {
        ...state,
        ...action.payload,
      };
    case USER_LOGOUT[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case USER_LOGOUT[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case USER_LOGOUT[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };

    case USER_RESTORE[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case USER_RESTORE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case USER_RESTORE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };

    case USER_HOTEL[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case USER_HOTEL[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case USER_HOTEL[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case EDIT_HOTEL_FETCH[REQUEST]:
      return {
        ...state,
      };
    case EDIT_HOTEL_FETCH[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        hotel: JSON.parse(localStorage.getItem('hotel')) || null,
        selectedMarket: JSON.parse(localStorage.getItem('selectedMarket')) || null,
      };
    case EDIT_HOTEL_FETCH[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case EDIT_HOTEL_SAVE[REQUEST]:
      return {
        ...state,
      };
    case EDIT_HOTEL_SAVE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        hotel: JSON.parse(localStorage.getItem('hotel')) || null,
      };
    case EDIT_HOTEL_SAVE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
