/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';

import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logoutUser, changeHotel } from '@/redux/actions';

import {
  Layout,
  Menu,
  Icon,
  Dropdown,
  Row,
  Col,
  Avatar,
  Select,
} from 'antd';
import './layout-hotel.css';

const { Option } = Select;

const {
  Header, Sider, Content,
} = Layout;


class LayoutHotel extends React.Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleChangeHotel = async (value) => {
    const selectedHotel = this.props.user.user.hotels.find(val => val.id === value);
    await this.props.actions.changeHotel(selectedHotel);
  }

  handleMenuClick = (e) => {
    this.props.history.push(`/${e.key}`);
  }


  renderHotelOptions = () => {
    return this.props.user.user.hotels.map(val => <Option value={val.id} key={val.id}>{val.name}</Option>);
  }

  render() {
    if (!localStorage.getItem('token')) {
      return <Redirect to={{ pathname: '/', state: { from: this.props.location } }} />;
    }

    const menu = (
      <Menu>
        <Menu.Item key="0">
          <a href="#">My account</a>
        </Menu.Item>
        <Menu.Item key="1">
          <a href="#">Password</a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3" onClick={this.props.actions.logoutUser}>Log out</Menu.Item>
      </Menu>
    );

    const currentPage = this.props.location.pathname.replace('/', '');

    const isCheckinOnly = this.props.user && this.props.user.user ? this.props.user.user.isCheckinOnly : false;
    const { isSuperAdmin } = this.props.user.user;

    if (isCheckinOnly && (currentPage === 'calendar' || currentPage === 'inventory')) {
      return <Redirect to={{ pathname: '/bookings', state: { from: this.props.location } }} />;
    }


    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <div className="logo">
            <img src={this.state.collapsed ? 'img/logo_small.svg' : 'img/logo.svg'} alt="logo" />
          </div>

          <Menu theme="dark" mode="inline" defaultSelectedKeys={[currentPage]}>
            {/* Basic user menu */}
            <Menu.Item disabled={isCheckinOnly} key="details" onClick={this.handleMenuClick}>
              <Icon type="details" style={{ fontSize: '18px' }} />
              <span>Details</span>
            </Menu.Item>
            <Menu.Item disabled={isCheckinOnly} key="calendar" onClick={this.handleMenuClick}>
              <Icon type="calendar" style={{ fontSize: '18px' }} />
              <span>Calendar</span>
            </Menu.Item>
            <Menu.Item disabled={isCheckinOnly} key="inventory" onClick={this.handleMenuClick}>
              <Icon type="container" style={{ fontSize: '18px' }} />
              <span>Inventory</span>
            </Menu.Item>
            <Menu.Item key="bookings" onClick={this.handleMenuClick}>
              <Icon type="book" style={{ fontSize: '18px' }} />
              <span>Bookings</span>
            </Menu.Item>

            {/* Super admin menu */}
            {isSuperAdmin
              ? (
                <Menu.Item key="search" onClick={this.handleMenuClick}>
                  <Icon type="search" style={{ fontSize: '18px' }} />
                  <span>Search</span>
                </Menu.Item>
              )
              : ''
            }
          </Menu>
        </Sider>
        <Layout style={{ height: '100vh' }}>
          <Header style={{ background: '#fff', padding: 0 }}>
            <Row type="flex" justify="space-between">
              <Col span={4}>
                <Icon
                  className="trigger"
                  type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                  onClick={this.toggle}
                  style={{
                    fontSize: '18px', lineHeight: '64px', padding: '0 24px', cursor: 'pointer', transition: 'color 0.3s',
                  }}
                />
              </Col>

              <Col span={19}>
                <Row type="flex" justify="end" align="middle">
                  <Select
                    showSearch
                    value={this.props.user.selectedHotel.id || ''}
                    style={{ width: 200, marginRight: '20px' }}
                    placeholder="Select a hotel"
                    optionFilterProp="children"
                    onChange={this.handleChangeHotel}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {this.renderHotelOptions()}
                  </Select>
                  <Avatar style={{ marginRight: '20px' }} icon="user" />
                  <Dropdown
                    overlay={menu}
                    trigger={['click']}
                  >
                    <a className="ant-dropdown-link" href="#">
                      {`${this.props.user.user.firstName} ${this.props.user.user.lastName} `}
                      <Icon type="down" />
                    </a>
                  </Dropdown>
                </Row>
              </Col>
              <Col span={1} />
            </Row>
          </Header>
          <Content style={{ margin: '24px 16px' }}>
            <div style={{ background: '#fff', padding: 24 }}>
              {this.props.children}
            </div>
          </Content>
        </Layout>
      </Layout>

    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    logoutUser,
    changeHotel,
  },
  dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutHotel);
