export function statusText(status) {
  const statuses = [
    'Active',
    'Expired',
    'Redeemed',
    'Refunded',
    'Capture',
  ];
  return statuses[status];
}
