import {
  REQUEST,
  SUCCESS,
  FAILURE,
  INVENTORY_FETCH,
  INVENTORY_SAVE,
} from '@/redux/store/action-types';
import axios from 'axios';
import action from '../action';
import Notification from '@/components/Notification';

export const fetchProducts = hotelId => async (dispatch) => {
  dispatch(action(INVENTORY_FETCH[REQUEST]));
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/hotel-products/${hotelId}`);

    dispatch(action(INVENTORY_FETCH[SUCCESS], {
      products: res.data.products,
    }));
  } catch (error) {
    dispatch(action(INVENTORY_FETCH[FAILURE], { error }));
  }
};

export const saveProduct = product => async (dispatch) => {
  dispatch(action(INVENTORY_SAVE[REQUEST]));
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/products/${product.id}`, { product });
    Notification.openNotification({
      message: 'Data is saved.',
      description: 'New inventory & prices will take effect next day',
      type: 'success',
    });
    dispatch(action(INVENTORY_SAVE[SUCCESS]));
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'We are working on fixing problems',
      type: 'error',
    });
    dispatch(action(INVENTORY_SAVE[FAILURE], { error }));
  }
};
